/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import SEO from "../../components/SEO";
import SignUpLayout from "../../components/SignUpLayout";
import { Opportunities112, Partnership112 } from "../../components/svg-assets/SignUpIcons";
import CoordPart from "../../components/sign-up/CoordPart";
import {
  EMPTY_REGION,
  defaultLeadData,
  CheckboxesField,
  ErrorRenderer,
  RegionField,
  SliderField,
  TextareaField,
  useField,
  validateRegion,
  validateRequired,
} from "../../components/sign-up/common";
import ThankYouPart from "../../components/sign-up/ThankYouPart";
import { TrucksField } from "../../components/sign-up/TrucksField";
import homepage from "../../lib/homepage";

const getFromSessionStorage = key => {
  const isSSR = typeof window === "undefined";

  if (!isSSR) {
    window.sessionStorage.getItem(key);
  } else {
    return null;
  }
};

const ShipperKindPart = ({ defaults, onContinue }) => {
  const { t } = useTranslation();

  const [kind, setKind] = useState(defaults.kind);
  const [tosAccepted, setTosAccepted] = useState(defaults.tosAccepted ?? false);
  const [newsletter, setNewsletter] = useState(defaults.newsletter);

  return (
    <div className="su-main fl-blue-context">
      <div className="fl-tag fl-naked-tag">
        <Trans i18nKey="sign-up.carrier.tag" /> (1/3)
      </div>
      <h2>
        <Trans i18nKey="sign-up.carrier.first.title" />
      </h2>
      <div className="su-two-choices">
        <input
          type="radio"
          id="kind-opportunity"
          name="kind"
          value="Opportunity"
          checked={kind === "Opportunity"}
          onChange={e => setKind(e.currentTarget.value)}
        />
        <label className="su-choice" htmlFor="kind-opportunity">
          <Opportunities112 />
          <p className="su-strong">
            <Trans i18nKey="sign-up.carrier.first.choices.opportunity.title" />
          </p>
          <Trans parent="p" i18nKey="sign-up.carrier.first.choices.opportunity.description" />
        </label>

        <input
          type="radio"
          id="kind-partner"
          name="kind"
          value="Partner"
          checked={kind === "Partner"}
          onChange={e => setKind(e.currentTarget.value)}
        />
        <label className="su-choice" htmlFor="kind-partner">
          <Partnership112 />
          <p className="su-strong">
            <Trans i18nKey="sign-up.carrier.first.choices.partner.title" />
          </p>
          <Trans parent="p" i18nKey="sign-up.carrier.first.choices.partner.description" />
        </label>
      </div>
      <label className="su-checkbox-label">
        <input
          type="checkbox"
          checked={tosAccepted}
          onChange={e => setTosAccepted(e.currentTarget.checked)}
        />
        <Trans
          i18nKey="sign-up.i-accept-the-tos"
          components={{
            touLink: (
              <a href={t("sign-up.tos-links.terms-of-use")} target="_blank" rel="noreferrer" />
            ),
            cgvLink: (
              <a href={t("sign-up.tos-links.terms-of-sale")} target="_blank" rel="noreferrer" />
            ),
            privacyLink: (
              <a href={t("sign-up.tos-links.privacy-policy")} target="_blank" rel="noreferrer" />
            ),
          }}
        />
      </label>
      <label className="su-checkbox-label">
        <input
          type="checkbox"
          checked={newsletter}
          onChange={e => setNewsletter(e.currentTarget.checked)}
        />
        <Trans
          i18nKey="sign-up.i-want-to-subscribe-to-the-newsletter"
          components={{
            privacyLink: (
              <a href={t("sign-up.tos-links.privacy-policy")} target="_blank" rel="noreferrer" />
            ),
          }}
        />
      </label>
      <p className="su-next-wrapper">
        <button
          className="su-next"
          disabled={!kind || !tosAccepted}
          onClick={() => onContinue(kind, tosAccepted, newsletter)}>
          <Trans i18nKey="sign-up.continue" />
        </button>
      </p>
    </div>
  );
};

const OrganizationPart = ({ defaults, onContinue, saving, globalError }) => {
  const { t } = useTranslation();

  const vehicleCount = useField(defaults.vehicleCount, () => null);
  const trucks = useField(
    defaults.trucks,
    validateRequired(t("sign-up.errors.at-least-one-truck-kind-is-required"))
  );
  const depotsArea = useField(defaults.depotsArea, validateRegion);
  const activityArea = useField(defaults.activityArea, validateRegion);
  const referrals = useField(
    defaults.referrals,
    validateRequired(t("sign-up.errors.referrals-are-required"))
  );
  const comments = useField(defaults.comments, () => null);

  const allFields = [vehicleCount, trucks, depotsArea, activityArea, referrals, comments];

  const forceValidation = () => {
    allFields.forEach(field => field.setError(field.validator()));
  };

  return (
    <div className="su-main fl-blue-context">
      <div className="fl-tag fl-naked-tag">
        <Trans i18nKey="sign-up.carrier.org.tag" /> (3/3)
      </div>
      <h2>
        <Trans i18nKey="sign-up.carrier.org.title" />
      </h2>

      <SliderField
        field={vehicleCount}
        label={t("sign-up.carrier.org.vehicle-count.label")}
        max={4}
        stepLabels={[
          t("sign-up.carrier.org.vehicle-count.choices.one-to-twenty"),
          t("sign-up.carrier.org.vehicle-count.choices.twenty-to-fifty"),
          t("sign-up.carrier.org.vehicle-count.choices.fifty-to-eighty"),
          t("sign-up.carrier.org.vehicle-count.choices.eighty-to-one-hundred-twenty"),
          t("sign-up.carrier.org.vehicle-count.choices.more-than-twenty"),
        ]}
      />

      <TrucksField label={t("sign-up.carrier.org.vehicle-types.label")} field={trucks} />

      <RegionField
        id="depots-area"
        label={t("sign-up.carrier.org.depots-area.label")}
        field={depotsArea}
      />

      <RegionField
        id="activity-area"
        label={t("sign-up.carrier.org.activity-area.label")}
        field={activityArea}
      />

      <CheckboxesField
        field={referrals}
        label={t("sign-up.referrals.label")}
        choices={{
          Customer: t("sign-up.referrals.choices.Customer"),
          Team: t("sign-up.referrals.choices.Team"),
          Investor: t("sign-up.referrals.choices.Investor"),
          Press: t("sign-up.referrals.choices.Press"),
          Other: t("sign-up.referrals.choices.Other"),
        }}
      />

      <TextareaField label={t("sign-up.comments.label")} field={comments} />

      <ErrorRenderer errorMessage={globalError} />

      <p className="su-next-wrapper">
        <button
          className={`su-next ${saving ? "su-next--saving" : ""}`}
          disabled={allFields.some(field => Boolean(field.validator()))}
          onFocus={() => forceValidation()}
          onMouseOver={() => forceValidation()}
          onClick={() => (!saving ? onContinue(...allFields.map(field => field.value)) : () => {})}>
          <Trans i18nKey="sign-up.send" />
        </button>
      </p>
    </div>
  );
};

const SignUpCarrierPage = () => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  const [leadId, setLeadId] = useState(null);

  const [kind, setKind] = useState(null);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [organization, setOrganization] = useState(getFromSessionStorage("organization"));
  const [siret, setSiret] = useState(getFromSessionStorage("siret"));
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(getFromSessionStorage("email"));
  const [phone, setPhone] = useState(getFromSessionStorage("phone"));
  const [vehicleCount, setVehicleCount] = useState(0);
  const [trucks, setTrucks] = useState([]);
  const [depotsArea, setDepotsArea] = useState(EMPTY_REGION);
  const [activityArea, setActivityArea] = useState(EMPTY_REGION);
  const [referrals, setReferrals] = useState([]);
  const [comments, setComments] = useState(null);

  const [globalError, setGlobalError] = useState(null);

  const [savingStep, setSavingStep] = useState(null);

  const backStatus = () => {
    if (progress === 0) {
      return {
        backTitle: t("sign-up.carrier.not-a-carrier"),
        backTarget: "/sign-up/",
      };
    } else if (progress === 3) {
      return {
        backTitle: t("sign-up.back-to-website"),
        backTarget: "/",
      };
    } else {
      return {
        backTitle: t("sign-up.previous-step"),
        backTarget: undefined,
        onBack: () => setProgress(progress - 1),
      };
    }
  };

  const { backTitle, backTarget, onBack } = backStatus();

  const [alreadySaving, setAlreadySaving] = useState(false);
  useEffect(() => {
    if (!savingStep && alreadySaving) {
      setAlreadySaving(false);
    }
    // Do not send data twice.
    if (!savingStep || alreadySaving) return;

    setAlreadySaving(true);

    let fields = {
      leadId,
      category: kind,
      accept_conditions: tosAccepted,
      acceptNewsletter: newsletter,
      companyName: organization,
      companyNumber: siret,
      firstName,
      lastName,
      email,
      phoneNumber: phone,
    };

    if (savingStep === 2) {
      fields = {
        ...fields,
        fleet: vehicleCount,
        trucks,
        from: depotsArea,
        to: activityArea,
        referrals,
        message: comments,
      };
    }

    const data = homepage.prepareLeadData({ ...defaultLeadData(), ...fields }, Object.keys(fields));

    homepage.sendLead(
      "carrier",
      data,
      successData => {
        const next = () => {
          if (successData.id) {
            setLeadId(successData.id);
          }
          setProgress(savingStep + 1);
          setGlobalError(null);
          setSavingStep(null);
        };

        if (savingStep === 2) {
          // Let's validate our lead, now it's complete!
          homepage.validateLead("carrier", leadId, next, () => {
            setSavingStep(null);
            setGlobalError(t("sign-up.errors.generic-api-error"));
            window.sessionStorage.removeItem("organization");
            window.sessionStorage.removeItem("siret");
            window.sessionStorage.removeItem("email");
            window.sessionStorage.removeItem("phone");
          });
        } else {
          next();
        }
      },
      error => {
        console.log({ error });
        setSavingStep(null);

        if (error?.response?.field) {
          const { field: fieldName, errorType } = error.response;

          // This "fake" t() is there to ensure all i18n keys are effectively used with
          // the same pattern, so you could lookup them using `t(…)` in your editor.
          // It doesn't return a string, but an object that will be read by
          // ErrorRenderer which will interpolate links (from the i18n) safely.
          const t = i18nKey => ({ i18nKey });

          const errorMessage =
            errorType === "duplicate"
              ? t("sign-up.errors.we-already-know-you-login-or-contact-us")
              : t("sign-up.errors.generic-api-error");

          setGlobalError({ fieldName, ...errorMessage });
        } else {
          setGlobalError(t("sign-up.errors.generic-api-error"));
        }
      }
    );
  }, [
    savingStep,
    alreadySaving,
    leadId,
    kind,
    tosAccepted,
    newsletter,
    organization,
    siret,
    firstName,
    lastName,
    email,
    phone,
    vehicleCount,
    trucks,
    depotsArea,
    activityArea,
    referrals,
    comments,
    t,
  ]);

  return (
    <SignUpLayout {...{ backTitle, backTarget, onBack }}>
      <SEO title={t("sign-up.carrier.html-title")} />
      {progress === 0 ? (
        <ShipperKindPart
          defaults={{ kind, tosAccepted, newsletter }}
          onContinue={(kind, tosAccepted, newsletter) => {
            setKind(kind);
            setTosAccepted(tosAccepted);
            setNewsletter(newsletter);
            setProgress(1);
          }}
        />
      ) : undefined}
      {progress === 1 ? (
        <CoordPart
          tag={`${t("sign-up.carrier.tag")} (2/3)`}
          defaults={{ organization, siret, firstName, lastName, email, phone }}
          saving={savingStep !== null}
          globalError={globalError}
          onContinue={(organization, siret, firstName, lastName, email, phone) => {
            setOrganization(organization);
            setSiret(siret);
            setFirstName(firstName);
            setLastName(lastName);
            setEmail(email);
            setPhone(phone);
            setGlobalError(null);
            setSavingStep(1);
          }}
        />
      ) : undefined}
      {progress === 2 ? (
        <OrganizationPart
          defaults={{ vehicleCount, trucks, depotsArea, activityArea, referrals, comments }}
          saving={savingStep !== null}
          globalError={globalError}
          onContinue={(vehicleCount, trucks, depotsArea, activityArea, referrals, comments) => {
            setVehicleCount(vehicleCount);
            setTrucks(trucks);
            setDepotsArea(depotsArea);
            setActivityArea(activityArea);
            setReferrals(referrals);
            setComments(comments);
            setGlobalError(null);
            setSavingStep(2);
          }}
        />
      ) : undefined}
      {progress === 3 ? (
        <ThankYouPart>
          <p className="su-thank-you-message">
            {kind === "Opportunity" ? (
              <Trans i18nKey="sign-up.thank-you.carrier.opportunity" />
            ) : (
              <Trans i18nKey="sign-up.thank-you.carrier.partner" />
            )}
          </p>
        </ThankYouPart>
      ) : undefined}
    </SignUpLayout>
  );
};

export default SignUpCarrierPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
