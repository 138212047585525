import React from "react";

export const Shipper112 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h112v112H0z"></path>
      <path
        stroke="#001428"
        strokeWidth="2"
        d="M56 84a8 8 0 110 16 8 8 0 010-16zM20 48a8 8 0 110 16 8 8 0 010-16z"></path>
      <path
        fill="#288CFF"
        d="M58 20a2 2 0 11-4 0 2 2 0 014 0m36 36a2 2 0 11-4 0 2 2 0 014 0m-72 0a2 2 0 11-4 0 2 2 0 014 0m36 36a2 2 0 11-4 0 2 2 0 014 0"></path>
      <path
        stroke="#001428"
        strokeWidth="2"
        d="M56 12a8 8 0 110 16 8 8 0 010-16zm36 36a8 8 0 110 16 8 8 0 010-16zm-36-8a16 16 0 110 32 16 16 0 010-32z"></path>
      <path
        stroke="#288CFF"
        strokeWidth="2"
        d="M56 48a8 8 0 110 16 8 8 0 010-16zM56 32v16m0 16v16M80 56H64m-16 0H32"></path>
    </g>
  </svg>
);

export const Carrier112 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path stroke="#001428" d="M95 56h-8v6h12z"></path>
      <path stroke="#001428" d="M82 69V51h16.5l7.5 11v14H18"></path>
      <path stroke="#001428" d="M18 48v-6h60l-.1 27"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        d="M38 79a4 4 0 108 0m-20 0a4 4 0 108 0m44 0a4 4 0 108 0m4 0a4 4 0 108 0"></path>
      <path
        stroke="#288CFF"
        d="M68 52H6m52 10H26m62-30H46m38-4l4 4-4 4m-8 54H52m21-4l4 4-4 4m-8-46l4 4-4 4m-11 2l4 4-4 4"></path>
      <path stroke="#001428" d="M18 72h88"></path>
    </g>
  </svg>
);

export const Opportunities112 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112">
    <g fill="none" fillRule="evenodd">
      <g strokeLinejoin="round" strokeWidth="2">
        <path stroke="#288CFF" d="M64 56a8 8 0 11-16 0 8 8 0 0116 0z"></path>
        <path stroke="#001428" d="M72 56a16 16 0 11-32 0 16 16 0 0132 0z"></path>
        <path
          stroke="#288CFF"
          d="M67 21a37 37 0 0122 20m0 30a36.8 36.8 0 01-21 20m-24 0a37.6 37.6 0 01-22-20m1-31c4.3-9 12.3-16 22-19"></path>
      </g>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M104 52.5a8 8 0 01-1 3.8l-7 11.2-7-11.2a8 8 0 01-1-3.8c0-4.7 3.6-8.5 8-8.5s8 3.8 8 8.5z"></path>
      <path fill="#288CFF" d="M98 52a2 2 0 11-4 0 2 2 0 014 0"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M24 52.5a8 8 0 01-1 3.8l-7 11.2-7-11.2a8 8 0 01-1-3.8c0-4.7 3.6-8.5 8-8.5s8 3.8 8 8.5z"></path>
      <path fill="#288CFF" d="M18 52a2 2 0 11-4 0 2 2 0 014 0"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M64 92.5a8 8 0 01-1 3.8l-7 11.2-7-11.2a8 8 0 01-1-3.8c0-4.7 3.6-8.5 8-8.5s8 3.8 8 8.5z"></path>
      <path fill="#288CFF" d="M58 92a2 2 0 11-4 0 2 2 0 014 0"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M64 12.5a8 8 0 01-1 3.8l-7 11.2-7-11.2a8 8 0 01-1-3.8C48 7.8 51.6 4 56 4s8 3.8 8 8.5z"></path>
      <path fill="#288CFF" d="M58 12a2 2 0 11-4 0 2 2 0 014 0"></path>
    </g>
  </svg>
);

export const Partnership112 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path stroke="#288CFF" d="M82 55.5a26.5 26.5 0 11-53 0 26.5 26.5 0 0153 0z"></path>
      <path
        stroke="#288CFF"
        d="M66.1 69.5C64 77 60 82 55.5 82 48.6 82 43 70.1 43 55.5S48.6 29 55.5 29c4.9 0 9.1 6 11.2 14.6M55.5 56H29m53 0h-7"></path>
      <path
        stroke="#288CFF"
        d="M75 73c-4.8-3-12-5-20-5s-15.2 2-20 5m-1-34c4.8 3.6 12.4 6 21 6s16.2-2.4 21-6"></path>
      <path
        stroke="#001428"
        d="M54.7 69.9c-5.1 4.6-10 8.7-14.5 12m41.7-41.7a204 204 0 01-19.4 22.3m19.4-22.3C89 30.5 92.6 23 90.3 20.7c-2.3-2.3-9.8 1.2-19.5 8.4M40.2 81.9C30.5 89 23 92.6 20.7 90.3c-2.3-2.3 1.2-9.8 8.4-19.5l3.1-3.3"></path>
      <path stroke="#001428" d="M72.3 62.7h-9.6v-9.6"></path>
    </g>
  </svg>
);

export const AToB112 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112">
    <g fill="none" fillRule="evenodd" strokeLinejoin="round" strokeWidth="2">
      <path
        stroke="#001428"
        d="M100 75.9c0 11.3-15.6 24.1-15.6 24.1S68.8 87.2 68.8 75.9a15.6 15.6 0 0131.2 0z"></path>
      <path stroke="#288CFF" d="M91.5 75.9a7.1 7.1 0 11-14.2 0 7.1 7.1 0 0114.2 0z"></path>
      <path
        stroke="#001428"
        d="M43.2 27.6c0 11.4-15.6 24.1-15.6 24.1S12 39 12 27.7a15.6 15.6 0 0131.2 0z"></path>
      <path
        stroke="#288CFF"
        d="M34.7 27.6a7.1 7.1 0 11-14.2 0 7.1 7.1 0 0114.2 0zm55.4 21.3l-5.7 5.7-5.7-5.7"></path>
      <path stroke="#288CFF" d="M47.5 27.6h27a10 10 0 019.9 10v17m-62.5 8.5l5.7-5.7 5.7 5.7"></path>
      <path stroke="#288CFF" d="M64.5 84.4h-27a10 10 0 01-9.9-10v-17"></path>
      <path stroke="#001428" d="M48.9 56h2.8m2.9 0h2.8m2.9 0h2.8"></path>
    </g>
  </svg>
);

export const LetsTalk112 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeWidth="2">
      <path
        stroke="#288CFF"
        strokeLinejoin="round"
        d="M39 37.5h36.9c.8 0 1.4.7 1.4 1.5v45.4c0 .8-.6 1.4-1.4 1.4H34.7L20.5 100V85.8h-7c-.9 0-1.5-.6-1.5-1.4V39c0-.8.6-1.5 1.4-1.5h17"></path>
      <path
        stroke="#222829"
        strokeLinejoin="round"
        d="M73 74.5H36.1c-.8 0-1.4-.7-1.4-1.5V27.6c0-.8.6-1.4 1.4-1.4h41.2L91.5 12v14.2h7c.9 0 1.5.6 1.5 1.4V73c0 .8-.6 1.5-1.4 1.5h-17"></path>
      <path stroke="#001428" d="M42.9 69.1L69 43"></path>
      <path stroke="#288CFF" d="M40 56l13.2-13.2m5.4 26.6l12.9-12.9"></path>
    </g>
  </svg>
);

export const TruckTaut33 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 34" width="99" height="34">
    <g fill="none" fillRule="evenodd">
      <path fill="#288CFF" d="M21.1 27.2h77.6v-3.5H21.1z"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.8 29.5c0 1.9 1.6 3.5 3.6 3.5 1.9 0 3.5-1.6 3.5-3.5m72.9 0c0 1.9 1.6 3.5 3.5 3.5 2 0 3.5-1.6 3.5-3.5m-16.4 0c0 1.9 1.6 3.5 3.5 3.5 2 0 3.5-1.6 3.5-3.5m-51 0c0 1.9 1.5 3.5 3.5 3.5s3.5-1.6 3.5-3.5m27.6 0c0 1.9 1.6 3.5 3.6 3.5s3.5-1.6 3.5-3.5"></path>
      <path fill="#288CFF" d="M4.7 5.1L0 14.4v12.8h18.7V5.1z"></path>
      <path stroke="#001428" strokeLinejoin="round" strokeWidth="2" d="M98.7 23.7H0"></path>
      <path fill="#F9FBFF" d="M4.1 14.4h11.1V8.6H7z"></path>
      <path stroke="#001428" strokeWidth="2" d="M4.1 14.4h11.1V8.6H7z"></path>
      <path fill="#288CFF" d="M21 20.8V.5h44.4v20.3H21.1z"></path>
      <path
        fill="#288CFF"
        fillOpacity="0.7"
        d="M65.4 20.8h5.5V.5h-5.5zm11.1 0H82V.5h-5.5zm11.1 0h5.5V.5h-5.5z"></path>
      <path fill="#288CFF" d="M70.9 20.8h5.6V.5h-5.6zm11.1 0h5.6V.5H82zm11.1 0h5.6V.5h-5.6z"></path>
    </g>
  </svg>
);

export const TruckTrailer33 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="99" height="34">
    <g fill="none" fillRule="evenodd">
      <path fill="#288CFF" d="M21.2 27.2h77.6v-3.5H21.2z"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 29.5C6 31.4 7.6 33 9.5 33c2 0 3.5-1.6 3.5-3.5m73 0c0 1.9 1.5 3.5 3.5 3.5 1.9 0 3.5-1.6 3.5-3.5m-16.4 0c0 1.9 1.6 3.5 3.5 3.5 2 0 3.5-1.6 3.5-3.5m-51.1 0c0 1.9 1.6 3.5 3.6 3.5 1.9 0 3.5-1.6 3.5-3.5m27.6 0c0 1.9 1.6 3.5 3.5 3.5 2 0 3.6-1.6 3.6-3.5"></path>
      <path fill="#288CFF" d="M4.8 5.1L.2 14.4v12.8h18.7V5.1z"></path>
      <path stroke="#001428" strokeLinejoin="round" strokeWidth="2" d="M98.8 23.7H.2"></path>
      <path fill="#F9FBFF" d="M4.3 14.4h11.1V8.6H7.2z"></path>
      <path stroke="#001428" strokeWidth="2" d="M4.3 14.4h11.1V8.6H7.2z"></path>
      <path fill="#288CFF" d="M21.2 20.8h77.6V.5H21.2z"></path>
    </g>
  </svg>
);

export const TruckFlatBed33 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="29">
    <g fill="none" fillRule="evenodd">
      <path fill="#288CFF" d="M21.6 22.2h77.7v-3.4H21.6z"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.3 24.5c0 2 1.6 3.5 3.6 3.5 1.9 0 3.5-1.6 3.5-3.5m73 0c0 2 1.6 3.5 3.6 3.5 1.9 0 3.5-1.6 3.5-3.5m-16.5 0c0 2 1.6 3.5 3.6 3.5s3.5-1.6 3.5-3.5m-51.1 0c0 2 1.5 3.5 3.4 3.5 2 0 3.6-1.6 3.6-3.5m27.7 0c0 2 1.6 3.5 3.5 3.5 2 0 3.5-1.6 3.5-3.5"></path>
      <path fill="#288CFF" d="M5.2.4L.5 9.6v12.6h18.7V.4z"></path>
      <path stroke="#001428" strokeLinejoin="round" strokeWidth="2" d="M99.3 18.8H.5"></path>
      <path fill="#F9FBFF" d="M4.6 9.6h11.1V3.9H7.5z"></path>
      <path stroke="#001428" strokeWidth="2" d="M4.6 9.6h11.1V3.9H7.5z"></path>
    </g>
  </svg>
);

export const TruckControllerTemp33 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="37">
    <g fill="none" fillRule="evenodd">
      <path fill="#288CFF" d="M21.4 23h58.4V2.4H21.4zm0 6.5h58.4V26H21.4z"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 31.8a3.5 3.5 0 007.1 0m50.4 0a3.5 3.5 0 007.1 0m-16.6 0a3.5 3.5 0 007.2 0M79.8 26H.1"></path>
      <path fill="#F9FBFF" d="M4.3 16.5h11.2v-5.8H7.2z"></path>
      <path fill="#288CFF" d="M12 7.1H4.8L0 16.5v13h19V0a7 7 0 00-7 7"></path>
      <path fill="#FFF" d="M4.3 16.5h11.2v-5.8H7.2z"></path>
      <path stroke="#001428" strokeWidth="2" d="M4.3 16.5h11.2v-5.8H7.2z"></path>
      <path
        fillRule="nonzero"
        stroke="#FFF"
        strokeWidth="2"
        d="M55.4 13h-8.9m6.6 3.8L48.7 9m0 7.8L53.2 9"></path>
    </g>
  </svg>
);

export const TruckDump33 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="34">
    <g fill="none" fillRule="evenodd" transform="translate(-526 -810)">
      <rect width="254" height="62" x="449" y="795" stroke="#288CFF" strokeWidth="2" rx="4"></rect>
      <path fill="#288CFF" d="M547.7 837.2h77.8v-3.5h-77.8z"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M532.5 839.5c0 1.9 1.6 3.5 3.5 3.5 2 0 3.5-1.6 3.5-3.5m73.1 0c0 1.9 1.6 3.5 3.5 3.5 2 0 3.5-1.6 3.5-3.5m-16.4 0c0 1.9 1.6 3.5 3.5 3.5 2 0 3.6-1.6 3.6-3.5m-51.3 0c0 1.9 1.6 3.5 3.6 3.5s3.5-1.6 3.5-3.5m27.7 0c0 1.9 1.6 3.5 3.6 3.5 1.9 0 3.5-1.6 3.5-3.5"></path>
      <path fill="#288CFF" d="M531.3 815.1l-4.7 9.3v12.8h18.8v-22.1z"></path>
      <path stroke="#001428" strokeLinejoin="round" strokeWidth="2" d="M625.5 833.7h-98.9"></path>
      <path fill="#F9FBFF" d="M530.7 824.4h11.2v-5.8h-8.2z"></path>
      <path stroke="#001428" strokeWidth="2" d="M530.7 824.4h11.2v-5.8h-8.2z"></path>
      <path fill="#288CFF" d="M547.7 810.5l77.8 12.9v7.4h-77.8z"></path>
    </g>
  </svg>
);

export const TruckCistern33 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="34">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#288CFF"
        d="M32.1.5h57.3C95 .5 99.6 5 99.6 10.6S95 20.8 89.4 20.8H32c-5.6 0-10.2-4.6-10.2-10.2S26.5.5 32 .5M21.9 27.2h77.7v-3.5H21.9z"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.6 29.5c0 1.9 1.6 3.5 3.5 3.5 2 0 3.6-1.6 3.6-3.5m73 0c0 1.9 1.6 3.5 3.5 3.5 2 0 3.6-1.6 3.6-3.5m-16.4 0c0 1.9 1.5 3.5 3.5 3.5s3.5-1.6 3.5-3.5m-51.2 0c0 1.9 1.6 3.5 3.5 3.5 2 0 3.5-1.6 3.5-3.5m27.8 0c0 1.9 1.5 3.5 3.5 3.5s3.5-1.6 3.5-3.5"></path>
      <path fill="#288CFF" d="M5.5 5.1L.8 14.4v12.8h18.7V5.1z"></path>
      <path stroke="#001428" strokeLinejoin="round" strokeWidth="2" d="M99.6 23.7H.8"></path>
      <path fill="#F9FBFF" d="M4.9 14.4H16V8.6H7.8z"></path>
      <path stroke="#001428" strokeWidth="2" d="M4.9 14.4H16V8.6H7.8z"></path>
    </g>
  </svg>
);

export const TruckActualTrailer33 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="115" height="35">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#288CFF"
        d="M21.8 21.1H67V.7H21.8zm47.6 0h45.2V.7H69.4zm-47.6 6.4h92.8V24H21.8z"></path>
      <path
        stroke="#001428"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.6 29.9a3.5 3.5 0 007 0m37.3 0a3.5 3.5 0 007 0m-16.4 0a3.5 3.5 0 007 0m50 0a3.5 3.5 0 007 0m-16.5 0a3.5 3.5 0 007.1 0"></path>
      <path fill="#288CFF" d="M5.4 5.4L.7 14.7v12.8h18.8V5.4z"></path>
      <path stroke="#001428" strokeLinejoin="round" strokeWidth="2" d="M114.6 24H.7"></path>
      <path fill="#F9FBFF" d="M4.8 14.7h11.1V8.9H7.7z"></path>
      <path stroke="#001428" strokeWidth="2" d="M4.8 14.7h11.1V8.9H7.7z"></path>
    </g>
  </svg>
);
