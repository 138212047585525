import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

import {
  TruckActualTrailer33,
  TruckCistern33,
  TruckControllerTemp33,
  TruckDump33,
  TruckTaut33,
  TruckTrailer33,
  TruckFlatBed33,
} from "../svg-assets/SignUpIcons";
import { ErrorRenderer } from "./common";

export const TrucksField = ({ label, field }) => {
  const { t } = useTranslation();

  const truckCheckbox = (key, truckLabel) => {
    const without = (field.value ?? []).filter(element => element !== key);

    return (
      <label className="su-checkbox-label">
        <input
          type="checkbox"
          checked={(field.value ?? []).includes(key)}
          onChange={e => {
            const newTrucks = e.currentTarget.checked ? without.concat([key]) : without;
            field.set(newTrucks);
            field.setError(field.validator(newTrucks));
          }}
        />
        {truckLabel}
      </label>
    );
  };

  const truckImage = (truckList, image) => {
    const classes = truckList.some(truck => (field.value ?? []).includes(truck))
      ? "su-truck-img su-truck-img--active"
      : "su-truck-img";

    return <div className={classes}>{image}</div>;
  };

  return (
    <div className="su-field su-truck-field">
      <span className="su-label">{label}</span>
      <div className="su-truck-group">
        {truckImage(["SemiTaut", "TractorTaut", "LightTaut"], <TruckTaut33 />)}
        {truckCheckbox("SemiTaut", t("sign-up.vehicles.SemiTaut"))}
        {truckCheckbox("TractorTaut", t("sign-up.vehicles.TractorTaut"))}
        {truckCheckbox("LightTaut", t("sign-up.vehicles.LightTaut"))}
      </div>
      <div className="su-truck-group">
        {truckImage(["SemiTrailer", "TractorTrailer", "LightTrailer"], <TruckTrailer33 />)}
        {truckCheckbox("SemiTrailer", t("sign-up.vehicles.SemiTrailer"))}
        {truckCheckbox("TractorTrailer", t("sign-up.vehicles.TractorTrailer"))}
        {truckCheckbox("LightTrailer", t("sign-up.vehicles.LightTrailer"))}
      </div>
      <div className="su-truck-group">
        {truckImage(["SemiFlatBed", "TractorFlatBed"], <TruckFlatBed33 />)}
        {truckCheckbox("SemiFlatBed", t("sign-up.vehicles.SemiFlatBed"))}
        {truckCheckbox("TractorFlatBed", t("sign-up.vehicles.TractorFlatBed"))}
      </div>
      <div className="su-truck-group">
        {truckImage(
          ["SemiControllerTemp", "TractorControllerTemp", "LightControllerTemp"],
          <TruckControllerTemp33 />
        )}
        {truckCheckbox("SemiControllerTemp", t("sign-up.vehicles.SemiControllerTemp"))}
        {truckCheckbox("TractorControllerTemp", t("sign-up.vehicles.TractorControllerTemp"))}
        {truckCheckbox("LightControllerTemp", t("sign-up.vehicles.LightControllerTemp"))}
      </div>
      <div className="su-truck-group">
        {truckImage(
          ["SteelDump", "AluminiumDump", "GrainDump", "SemiDump", "TratorDump"],
          <TruckDump33 />
        )}
        {truckCheckbox("SteelDump", t("sign-up.vehicles.SteelDump"))}
        {truckCheckbox("AluminiumDump", t("sign-up.vehicles.AluminiumDump"))}
        {truckCheckbox("GrainDump", t("sign-up.vehicles.GrainDump"))}
        {truckCheckbox("SemiDump", t("sign-up.vehicles.SemiDump"))}
        {truckCheckbox("TratorDump", t("sign-up.vehicles.TratorDump"))}
      </div>
      <div className="su-truck-group">
        {truckImage(
          ["MonoTankCistern", "MultiTankCistern", "PulverulantCistern", "PushFloorCistern"],
          <TruckCistern33 />
        )}
        {truckCheckbox("MonoTankCistern", t("sign-up.vehicles.MonoTankCistern"))}
        {truckCheckbox("MultiTankCistern", t("sign-up.vehicles.MultiTankCistern"))}
        {truckCheckbox("PulverulantCistern", t("sign-up.vehicles.PulverulantCistern"))}
        {truckCheckbox("PushFloorCistern", t("sign-up.vehicles.PushFloorCistern"))}
      </div>
      <div className="su-truck-group">
        {truckImage(["TruckTrailer"], <TruckActualTrailer33 />)}
        {truckCheckbox("TruckTrailer", t("sign-up.vehicles.TruckTrailer"))}
      </div>
      <ErrorRenderer field={field} />
    </div>
  );
};
