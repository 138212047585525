import React from "react";

import { Link, Trans } from "gatsby-plugin-react-i18next";
import { SuccessCircle } from "../svg-assets/Icons";

const ThankYouPart = ({ children }) => {
  return (
    <div className="su-main fl-blue-context">
      <div className="fl-tag fl-naked-tag">
        <Trans i18nKey="sign-up.thank-you.tag" />
      </div>
      <h2>
        <Trans i18nKey="sign-up.thank-you.title" />
      </h2>

      <div className="su-success-circle">
        <SuccessCircle />
      </div>

      {children}

      <p className="su-next-wrapper">
        <Link to="/" className="su-next">
          <Trans i18nKey="sign-up.back-to-website" />
        </Link>
      </p>
    </div>
  );
};

export default ThankYouPart;
